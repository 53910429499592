exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-administrators-js": () => import("./../../../src/pages/administrators.js" /* webpackChunkName: "component---src-pages-administrators-js" */),
  "component---src-pages-board-of-directors-js": () => import("./../../../src/pages/board-of-directors.js" /* webpackChunkName: "component---src-pages-board-of-directors-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-clinical-advisory-board-js": () => import("./../../../src/pages/clinical-advisory-board.js" /* webpackChunkName: "component---src-pages-clinical-advisory-board-js" */),
  "component---src-pages-company-research-js": () => import("./../../../src/pages/company-research.js" /* webpackChunkName: "component---src-pages-company-research-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-corporate-governance-js": () => import("./../../../src/pages/corporate-governance.js" /* webpackChunkName: "component---src-pages-corporate-governance-js" */),
  "component---src-pages-education-resources-js": () => import("./../../../src/pages/education-resources.js" /* webpackChunkName: "component---src-pages-education-resources-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-indications-for-use-js": () => import("./../../../src/pages/indications-for-use.js" /* webpackChunkName: "component---src-pages-indications-for-use-js" */),
  "component---src-pages-investor-relations-js": () => import("./../../../src/pages/investor-relations.js" /* webpackChunkName: "component---src-pages-investor-relations-js" */),
  "component---src-pages-investor-reports-js": () => import("./../../../src/pages/investor-reports.js" /* webpackChunkName: "component---src-pages-investor-reports-js" */),
  "component---src-pages-media-announcement-js": () => import("./../../../src/pages/media-announcement.js" /* webpackChunkName: "component---src-pages-media-announcement-js" */),
  "component---src-pages-patients-js": () => import("./../../../src/pages/patients.js" /* webpackChunkName: "component---src-pages-patients-js" */),
  "component---src-pages-payers-js": () => import("./../../../src/pages/payers.js" /* webpackChunkName: "component---src-pages-payers-js" */),
  "component---src-pages-physicians-js": () => import("./../../../src/pages/physicians.js" /* webpackChunkName: "component---src-pages-physicians-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-salix-demo-js": () => import("./../../../src/pages/salix-demo.js" /* webpackChunkName: "component---src-pages-salix-demo-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-who-we-are-js": () => import("./../../../src/pages/who-we-are.js" /* webpackChunkName: "component---src-pages-who-we-are-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/blog-index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-events-index-js": () => import("./../../../src/templates/events-index.js" /* webpackChunkName: "component---src-templates-events-index-js" */),
  "component---src-templates-news-index-js": () => import("./../../../src/templates/news-index.js" /* webpackChunkName: "component---src-templates-news-index-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-presentations-and-webcasts-index-js": () => import("./../../../src/templates/presentations-and-webcasts-index.js" /* webpackChunkName: "component---src-templates-presentations-and-webcasts-index-js" */),
  "component---src-templates-teams-post-js": () => import("./../../../src/templates/teams-post.js" /* webpackChunkName: "component---src-templates-teams-post-js" */)
}


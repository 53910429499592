import App from './src/App';

import './src/css/main.scss';

export const wrapRootElement = App;

export const onRouteUpdate = () => {
  if (typeof window !== `undefined`) {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 50);
  }
};

export const shouldUpdateScroll = () => {
  return false;
};
